import { AnyAction } from 'redux';
import { filterActions } from 'redux-ignore';

export interface IConfig {
    grpcAddress: string;
}

function configReducer(state: IConfig = {} as any, _action: AnyAction) {
    return state; // config never changes
}

export const config = filterActions(configReducer, () => false);
