import * as React from "react";
import { Watch } from "scrollmonitor-react";
// import { Col, Row } from "reactstrap";
import { DownloadButtons } from "./DownloadButtons";

const welcomeVideo = require('../../web-styles/media/video/welcome-video.mp4');
const styles = require(`./styles/sections.scss`);

interface IWelcomeAreaProps {
    enterViewport: (watcher: any, childProps: any) => void;
    exitViewport: (watcher: any, childProps: any) => void;
}

export const WelcomeArea = Watch(class extends React.Component<IWelcomeAreaProps, {}> {
    render() {
        return (
            // <div>
            <section className={`${styles["welcome-area"]} ${styles.section}`}>
                <video autoPlay loop muted playsInline className={styles.video}>
                    <source src={welcomeVideo} type="video/mp4" />
                </video>
                <header className={styles.header}>
                    <div className={styles.logo} />
                    {/*<button disabled={true} className={styles.btn}>Download app</button>*/}
                </header>

                <div className={styles.bodyW}>
                    <div className={styles.symbol} />
                    <div className={styles.title}>Challenge the world</div>
                    <div className={styles.subtitle}>Dance videos. Casual people.</div>
                    <DownloadButtons />
                    {/* <Row className={styles['discover-area']}>
                        <Col lg={{ size: 10 }} md={{ size: 12 }}>
                            <div className={styles.title}>What is Beatroot</div>
                            <div className={styles.txt}>A short-form video app for discovering fun and casual
                                dance videos. Capture, showcase and measure up your best dance moves alone or
                                together with others!
                            </div>
                            <DownloadButtons />
                        </Col>
                    </Row> */}
                </div>
            </section>
            // </div>
        );
    }
});