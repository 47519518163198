import React from 'react';

const IconSolo = () => {
    return (
        <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="lp-icon_solo" fill-rule="nonzero">
                <g id="Group" opacity="0.8" fill="#FFFFFF" className="icon-bg">
                    <path d="M0,25 C0,19.9 0.4,10.4 5.4,5.4 C10.4,0.4 19.9,0 25,0 C30.1,0 39.6,0.4 44.6,5.4 C49.6,10.4 50,19.9 50,25 C50,30.1 49.6,39.6 44.6,44.6 C39.6,49.6 30.1,50 25,50 C19.9,50 10.4,49.6 5.4,44.6 C0.4,39.6 0,30.1 0,25 Z" id="profile_bg-2"></path>
                </g>
                <path d="M18.65,36.85 C19.3612153,36.8513795 20.0569925,36.6426463 20.65,36.25 L35.85,27.45 C37.05,26.75 37.75,26.0499932 37.75,24.95 C37.75,23.85 37.05,23.15 35.85,22.45 L20.65,13.65 C20.0569925,13.2573537 19.3612153,13.0486205 18.65,13.0499932 C17.2991689,13.0827716 16.2276313,14.1989566 16.25,15.5499932 L16.25,34.35 C16.1571451,35.669791 17.1330439,36.823126 18.45,36.95 C18.55,36.85 18.55,36.85 18.65,36.85 Z" id="Path_2929" fill="#161823" className="icon-shape"></path>
            </g>
        </svg>
    );
}
export default IconSolo;