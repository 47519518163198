import * as React from "react";
import {Button} from "reactstrap";
import animateScrollTo from 'animated-scroll-to';
import IconCreate from '../../../web-styles/media/svg/Create';
import IconDiscover from "../../../web-styles/media/svg/Discover";
import IconDuo from "../../../web-styles/media/svg/Duo";
import IconSolo from "../../../web-styles/media/svg/Solo";
import IconRelay from "../../../web-styles/media/svg/Video";

const styles = require(`../styles/navigation.scss`);

interface INavigationProps {
    activeId: string;
    isMobile?: boolean;
}

interface INavigationState {
    isScrolling: boolean;
    scrolledId: string;
}

export class Navigation extends React.Component<INavigationProps, INavigationState> {
    constructor(props: INavigationProps) {
        super(props);

        this.state = {
            isScrolling: false,
            scrolledId: props.activeId
        }
    }

    getIcon(icon) {
        switch (icon) {
            default:
            case 'create':
                return <IconCreate />;
            case 'discover':
                return <IconDiscover />;
            case 'duo':
                return <IconDuo />;
            case 'solo':
                return <IconSolo />;
            case 'relay':
                return <IconRelay />;
        }
    }

    getButtonElement(title: string, icon: string) {
        const id = title.toLowerCase();

        return <Button
            className={`${styles.shape} `}
            title={title}
            active={id === (this.state.isScrolling ? this.state.scrolledId : this.props.activeId)}
            onClick={async () => {
                let verticalOffset = 0;
                let minDuration = 1800;

                if (this.props.isMobile) {
                    const aside = document.getElementById("aside");
                    minDuration = 200;

                    verticalOffset -= id === "dance" ? -1 : (aside && aside.scrollHeight) || 0;
                }

                this.setState({
                    isScrolling: true,
                    scrolledId: id
                });

                await animateScrollTo(document.getElementById(id) as Element, {minDuration: minDuration, verticalOffset});

                this.setState({
                    isScrolling: false
                })
            }}
        >
            {this.getIcon(icon)}
        </Button>;
    }

    render() {

        return (
            <div className={`${styles.navigation}`}>

                {this.getButtonElement('Dance', 'create')}
                {this.getButtonElement('Discover', 'discover')}
                {this.getButtonElement('Solo', 'duo')}
                {this.getButtonElement('Duo', 'solo')}
                {this.getButtonElement('Relay', 'relay')}

            </div>
        );
    }
}