import { IAuth } from "./auth";

export enum eActionUser {
    SET_AUTH = 'SET_AUTH',
    CLEAR_AUTH = 'CLEAR_AUTH'
}


export const setAuth = (authObj: IAuth) => ({
    type: eActionUser.SET_AUTH,
    payload: authObj
});


export const clearAuth = () => ({
    type: eActionUser.CLEAR_AUTH,
});
