import * as React from "react";
import {Col, Container, Row} from "reactstrap";
import {Navigation} from "./video-section/Navigation";
import {WrapperContent} from "./video-section/WrapperContent";
import {Phone} from "./video-section/Phone";

export const styles = require(`./styles/sections.scss`);

export enum eAsidePosition {
    TOP,
    MIDDLE,
    BOTTOM
}

interface IVideoAreaMobileProps {
    asidePosition: eAsidePosition;
}

interface IVideoAreaMobileState {
    activeContentSection: string;
}

export class VideoAreaMobile extends React.PureComponent<IVideoAreaMobileProps, IVideoAreaMobileState> {
    private _danceOffset = 0;
    private _discoverOffset = 0;
    private _soloOffset = 0;
    private _duoOffset = 0;
    private _relayOffset = 0;
    private _viewportMinOffset = 0

    constructor(props: IVideoAreaMobileProps) {
        super(props);

        this.state = {
            activeContentSection: "dance"
        };
    }
    componentDidMount() {
        this._danceOffset = (document.getElementById("dance")?.offsetTop || 0) + 450;
        this._discoverOffset = document.getElementById("discover")?.offsetTop || 0;
        this._soloOffset = document.getElementById("solo")?.offsetTop || 0;
        this._duoOffset = document.getElementById("duo")?.offsetTop || 0;
        this._relayOffset = document.getElementById("relay")?.offsetTop || 0;
        this._viewportMinOffset = (VideoAreaMobile.getViewportSize().height - (document.getElementById("aside")?.scrollHeight || 0)) / 1.5;

        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    private handleScroll() {
        const currentScroll = window.scrollY - this._viewportMinOffset;

        if ((currentScroll >= this._relayOffset)) {
            this.setState({
                activeContentSection: "relay"
            });
        } else if ((currentScroll >= this._duoOffset)) {
            this.setState({
                activeContentSection: "duo"
            });
        } else if ((currentScroll >= this._soloOffset)) {
            this.setState({
                activeContentSection: "solo"
            });
        } else if ((currentScroll >= this._discoverOffset)) {
            this.setState({
                activeContentSection: "discover"
            });
        } else if ((currentScroll >= this._danceOffset)) {
            this.setState({
                activeContentSection: "dance"
            });
        }
    }

    private static getViewportSize() {
        let e: any = window;
        let a = 'inner';

        if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
        }

        return {
            width : e[a + 'Width'],
            height : e[a + 'Height']
        };
    }

    render() {
        return (
            <section className={`${styles["video-area"]} ${styles.section} video-area`}>
                <Container>
                    <Row className={`justify-content-between`}>
                        <div
                            id={"aside"}
                            className={`
                                ${styles.aside} 
                                ${styles.positionFixed} 
                                ${this.props.asidePosition == eAsidePosition.TOP ? styles.asideTop : styles.asideBottom} 
                                ${this.props.asidePosition != (eAsidePosition.MIDDLE || eAsidePosition.BOTTOM) && styles.positionAbsolute}
                            `}
                        >
                            <div className={styles['phone-section']} >
                                <div className={`${styles.wrapper} ${styles.phone}`}>
                                    <Phone wallpaper={this.state.activeContentSection}/>
                                </div>
                            </div>

                            <Navigation activeId={this.state.activeContentSection} isMobile={true} />
                            <div className={styles['bg-mobile']} />
                        </div>

                        <Col xl={{size: 6, order: 2}} lg={{size: 12}} className={`${styles['mobile-video']} d-xl-block`}>
                            <WrapperContent
                                idContent={"danceContent"}
                                id={"dance"}
                                subtitle={"How it works"}
                                title={"Create. Compete. Collaborate."}
                                txt={"Dance to your favorite beat alone, with a friend, or with the whole world. See how your moves stack up to others, discover awesome dance videos and join a global dance lover community"}
                                buttonClass={'primary'}
                            />
                            <WrapperContent
                                id={"discover"}
                                idContent={"discoverContent"}
                                subtitle={"Discover"}
                                title={"Freshly baked videos. Every second"}
                                txt={"Tap into an endless stream of original, fresh & creative dance videos. Search, explore and see more of what interests you based on people you follow and videos you like. Inspire and get inspired by others!"}
                                buttonClass={'secondary'}
                            />
                            <WrapperContent
                                id={"solo"}
                                idContent={"soloContent"}
                                subtitle={"Solo Video"}
                                title={"Showoff your best dance moves"}
                                txt={"Express yourself by dancing to your favorite beat and showoff your talent for the whole world to admire."}
                                buttonClass={'secondary'}
                            />
                            <WrapperContent
                                id={"duo"}
                                idContent={"duoContent"}
                                subtitle={"Duo Challenge"}
                                title={"Why dance alone?"}
                                txt={"Got a sick move? Turn your video into a casual challenge and let the community reply with their own version. It’s all about fun, casual and entertaining videos!"}
                                // buttonTitle={"Learn More"}
                                buttonClass={'secondary'}
                            />
                            <WrapperContent
                                id={"relay"}
                                idContent={"videoContent"}
                                subtitle={"Video Relay"}
                                title={"Dance it forward!"}
                                txt={"Record the first 15s of your video and give control to your friends or followers to finish the story! Throw a fun video to them and see what comes out at the other end!"}
                                buttonClass={'primary'}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}