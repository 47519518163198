import * as auth from './auth';
import * as config from './config';
import * as router from '@beatroot/web-framework-utils-nodejs/router/reducers';
import * as helpers from '@beatroot/web-helper-component-utils-nodejs/reducers';

export const reducers = {
    ...config,
    ...auth,
    ...helpers,
    ...router
};

type Diff<T, U> = T extends U ? never : T;
type ReducerFuncs<T extends { [k: string]: (...args: any[]) => any }> = {
    [k in keyof T]: Diff<ReturnType<T[k]>, undefined>;
};

export type IAppState = ReducerFuncs<typeof reducers>;
