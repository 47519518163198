import {AnyAction} from 'redux';
import {filterActions} from 'redux-ignore';
import {eActionUser} from './actions';

const initialState: IAuth = { email: '', fullname: '', token: '', username: '', role: '' };
export interface IAuth {
    // language: string;
    email: string;
    fullname: string;
    token: string;
    username: string;
    role: string;
}

function authReducer(state: IAuth = initialState, action: AnyAction): IAuth {
    switch (action.type) {
        case eActionUser.SET_AUTH:
            return action.payload as IAuth;//it can be usually found in state
        case eActionUser.CLEAR_AUTH:
            return initialState;
        default:
            return state; // config never changes
    }
}

export const auth = filterActions(authReducer, [eActionUser.SET_AUTH, eActionUser.CLEAR_AUTH]);
