import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import createRootReducer from './rootReducer';

type StoreParams = {
    initialState?: { [key: string]: any };
    middleware?: any[];
};

function composeEnhancers(...args) {
    const devtools =
        typeof window !== 'undefined' &&
        typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

    if (devtools) {
        return devtools({
            actionsBlacklist: [],
            stateSanitizer: (state: any) => ({
                ...state,
            })
        })(...args);
    } else {
        return compose(...args);
    }
}

export const configureStore = ({ initialState, middleware = [] }: StoreParams) => {
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        createRootReducer(),
        initialState,
        composeEnhancers(applyMiddleware(...middleware.concat(sagaMiddleware)))
    );

    // sagaMiddleware.run(rootSaga);

    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('./rootReducer', () => store.replaceReducer(require('./rootReducer').default));
        }
    }

    return store;
};

export default configureStore;
