import React from 'react';

const IconDuo = () => {
    return (
        <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="lp-icon_duo-battle" fill-rule="nonzero">
                <g id="Group" opacity="0.8" fill="#FFFFFF" className="icon-bg">
                    <path d="M0,25 C0,19.9 0.4,10.4 5.4,5.4 C10.4,0.4 19.9,0 25,0 C30.1,0 39.6,0.4 44.6,5.4 C49.6,10.4 50,19.9 50,25 C50,30.1 49.6,39.6 44.6,44.6 C39.6,49.6 30.1,50 25,50 C19.9,50 10.4,49.6 5.4,44.6 C0.4,39.6 0,30.1 0,25 Z" id="profile_bg-2"></path>
                </g>
                <path d="M29.86,10.6 L21.56,10.6 C17.7685078,10.7941902 14.8414162,14.0068517 15,17.8 L15,21.9 C15.895455,21.3346371 16.9419351,21.0555757 18,21.1 L21.3,21.1 C22.9749316,21.1982339 24.3921644,22.3725124 24.8,24 L32.4,24 C34.6103862,23.8780653 36.418805,22.1958152 36.7,20 L36.7,17.8 C36.6896726,13.9660463 33.6883864,10.8067976 29.86,10.6 L29.86,10.6 Z M17.56,39.4 L33.56,39.4 L33.56,33.9 L17.56,33.9 L17.56,39.4 Z M23.26,35.7 L27.66,35.7 L27.66,37.6 L23.26,37.6 L23.26,35.7 Z M24.56,25.6 C24.2499344,27.286918 22.7751542,28.5088787 21.06,28.5 L17.06,28.5 L17.06,26.6 L21.06,26.6 C21.9700159,26.3926052 22.6155903,25.5833496 22.6155903,24.65 C22.6155903,23.7166504 21.9700159,22.9073948 21.06,22.7 L17.76,22.7 C15.2561181,22.8625588 13.3083829,24.9408467 13.3083829,27.45 C13.3083829,29.9591533 15.2561181,32.0374412 17.76,32.2 L33.06,32.2 C35.1927756,30.8277479 36.4463127,28.4346317 36.36,25.9 L36.36,23.7 C35.2628686,24.9170976 33.6985832,25.6082934 32.06,25.6 L24.56,25.6 Z" id="Path_2635" fill="#161823" className="icon-shape"></path>
            </g>
        </svg>
    );
}
export default IconDuo;