import * as React from "react";
import {Button} from "reactstrap";
import {btn} from "../LandingPage";

const styles = require(`./styles/sections.scss`);

export const DownloadButtons = class extends React.Component<{}, {}> {
    render() {
        return (
            <div className={styles["btn-group"]}>
                <Button className={`${btn.icon} order-2`} onClick={() => window.open('https://play.google.com/store/apps/details?id=io.inradius.beatroot&referrer=utm_source%3DWebsite')}>
                    <img src={require("../../web-styles/media/img/android-btn.png")} title={"Android"}
                         width={"auto"} height={50} alt={"Google Play Store"}/>
                </Button>
                <Button className={`${btn.icon} order-1`} onClick={() => window.open('https://apps.apple.com/app/apple-store/id1541343973?pt=117896149&ct=Website&mt=8')}>
                    <img src={require("../../web-styles/media/img/ios-btn.png")} title={"iOS"} width={"auto"}
                         height={50} alt={"App Store"}/>
                </Button>
            </div>
        );
    }
}