import * as React from "react";

const dance = require('../../../web-styles/media/video/dance.mp4');
const discover = require('../../../web-styles/media/video/discover.mp4');
const solo = require('../../../web-styles/media/video/solo.mp4');
const duo = require('../../../web-styles/media/video/duo.mp4');
const relay = require('../../../web-styles/media/video/relay.mp4');
const phone = require(`../styles/phone.scss`);

interface IPhoneProps {
    wallpaper: string;
}

export class Phone extends React.PureComponent<IPhoneProps, {}> {

    renderWallpaper() {
        switch (this.props.wallpaper) {
            default:
            case 'dance':
                return (
                    <video
                        autoPlay={true}
                        controls={false}
                        muted={true}
                        loop={true}
                        playsInline={true}
                        preload={"auto"}
                        height={'100%'}
                        className={`${phone['react-player']} ${phone.wallpaper} ${phone.fadeIn}`}
                        src={dance}
                        key={this.props.wallpaper}
                    >
                    </video>
                );
            case 'discover':
                return (
                    <video
                        autoPlay={true}
                        controls={false}
                        muted={true}
                        loop={true}
                        playsInline={true}
                        preload={"auto"}
                        height={'100%'}
                        className={`${phone['react-player']} ${phone.wallpaper} ${phone.fadeIn}`}
                        src={discover}
                        key={this.props.wallpaper}
                    >
                    </video>
                );
            case 'solo':
                return (
                    <video
                        autoPlay={true}
                        controls={false}
                        muted={true}
                        playsInline={true}
                        loop={true}
                        preload={"auto"}
                        height={'100%'}
                        className={`${phone['react-player']} ${phone.wallpaper} ${phone.fadeIn}`}
                        src={solo}
                        key={this.props.wallpaper}
                    >
                    </video>
                );
            case 'duo':
                return (
                    <video
                        autoPlay={true}
                        controls={false}
                        muted={true}
                        playsInline={true}
                        loop={true}
                        preload={"auto"}
                        height={'100%'}
                        className={`${phone['react-player']} ${phone.wallpaper} ${phone.fadeIn}`}
                        src={duo}
                        key={this.props.wallpaper}
                    >
                    </video>
                );
            case 'relay':
                return (
                    <video
                        autoPlay={true}
                        controls={false}
                        muted={true}
                        playsInline={true}
                        loop={true}
                        preload={"auto"}
                        height={'100%'}
                        className={`${phone['react-player']} ${phone.wallpaper} ${phone.fadeIn}`}
                        src={relay}
                        key={this.props.wallpaper}
                    >
                    </video>
                );
        }
    }

    render() {
        return (
            <div className={`${phone["phone-device"]} ${phone["iphone-x"]}`}>
                <div className={`${phone.screen} ${phone.fadeInDown}`}>
                    {this.renderWallpaper()}
                </div>
            </div>
        );
    }
}