import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { error } from '@beatroot/web-helper-component-utils-nodejs/actions';
import { Route, Switch } from "react-router";
import { pageRoutes, pages } from "./routes";
import { connectRouter, NOT_FOUND } from "@beatroot/web-framework-utils-nodejs/router/index";
import LandingPage from "./content/LandingPage";
// import ComingSoon from "./content/ComingSoon";
import Error from "./content/not-found/Error";
import TermsPage from "./content/pages/Terms";
import Privacy from "./content/pages/Privacy";
import Guidelines from "./content/pages/Guidelines";
import TagManager from 'react-gtm-module' // used for Google Tag Manager (-> Alexis)


// import layout styles
require('bootstrap/scss/bootstrap.scss');
require('@beatroot/web-styles-utils-nodejs/layout/reset');
require('../app/web-styles/layout/default');
require('../app/web-styles/templates/template-colors.scss');

const metaOGImage = require('./web-styles/media/img/logo_og.jpg');
const styles = require('./web-styles/layout/structure.scss');

// Setup Google Tag Manager
const tagManagerArgs = {
    gtmId: 'GTM-P7HJMJ3'
}

TagManager.initialize(tagManagerArgs)

interface IAppProps {
    alertError: typeof error;
}

class App extends React.PureComponent<IAppProps, {}> {
    componentDidMount() {
        const WebFont = require('webfontloader');

        WebFont.load({
            google: {
                families: ['Lato:wght@100;300;400;700&family=Staatliches&display=swap']
            }
        });
    }

    render() {
        return (
            <div tabIndex={0} className={styles.app}>
                <Helmet
                    defaultTitle="Beatroot • Dance Videos"
                    titleTemplate="%s • Beatroot"
                    link={[{
                        rel: 'icon',
                        sizes: '16x16',
                        type: 'image/ico',
                        href: require('./web-styles/media/img/favicon.ico')
                    }]}
                >
                    <meta name="description"
                        content="A short-form video app for discovering fun and casual dance videos. Capture, showcase and measure up your best dance moves alone or together with others!" />
                    <meta property="og:title" content="Beatroot • Dance Videos" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://beatroot.app/" />
                    <meta property="og:image" name="image" content={`https://beatroot.app${metaOGImage}`} />
                    <meta property="og:image:width" content="300" />
                    <meta property="og:image:height" content="300" />
                </Helmet>
                <Switch>
                    <Route exact path={pageRoutes.LANDING_PAGE} component={connectRouter(pages.COMING_SOON, () => <LandingPage />)} />
                    <Route exact path={pageRoutes.TERMS} component={connectRouter(pages.TERMS, () => <TermsPage />)} />
                    <Route exact path={pageRoutes.PRIVACY} component={connectRouter(pages.PRIVACY, () => <Privacy />)} />
                    <Route exact path={pageRoutes.GUIDELINES} component={connectRouter(pages.GUIDELINES, () => <Guidelines />)} />
                    <Route component={connectRouter(NOT_FOUND, Error)} />
                </Switch>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        alertError: error
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(App);
