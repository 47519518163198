import * as React from "react";
import {CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/pro-regular-svg-icons";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {pageRoutes} from "../../routes";
import queryString from "query-string";

const styles = require(`./styles/pages.scss`);
const btn = require(`../../web-styles/components/btns.scss`);


class Privacy extends React.Component<RouteComponentProps, {}> {
    render() {
        const parsed = queryString.parse(this.props.location.search);
        const firstTemplate = (parsed.template instanceof Array ? parsed.template[0] : parsed.template) || 'default';

        return (
            <Container fluid={true} className={styles[firstTemplate]}>
                <CardHeader className={`${styles.header} ${parsed.hideHeader === "true" ? styles['hide-header'] : ''}`}>
                    <Link to={pageRoutes.LANDING_PAGE} className={`${styles.txt} ${btn.link}`}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                        Back to main page
                    </Link>
                    <div className={styles.logo} />
                </CardHeader>

                <CardBody className={`${styles.body} ptb_100`}>
                    <Container className={`container`}>
                        <Row className={`row justify-content-center`}>
                            <div className={`col-12 col-md-10 col-lg-8`}>
                                <div className={`section-heading text-center`}>
                                    <h2 className={` ${styles.h2} text-capitalize`}>Privacy Policy</h2>
                                    <p className={`${styles.subtitle} mt-5 mb-5`}>Welcome to the Beatroot Privacy Policy ("Policy")! This Policy describes the information we collect, how that information may be used, with whom it may be shared, and your choices about such uses and disclosures.
                                        We encourage you to read this Policy carefully before using our application or services or transacting business with us. By using our website or application (our “Service”), you are accepting the practices described in this Privacy Policy. If you have any questions or comments about this Policy, please feel free to contact us at <a href={'mailto:feedback@beatroot.app'} title={'feedback@beatroot.app'}>feedback@beatroot.app</a></p>
                                </div>
                            </div>
                        </Row>
                        <Row className={`row justify-content-center`}>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Information We Collect</h3>
                            </Col>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>In order to verify our users and enhance Beatroot experience we need you to provide us basic profile details such as your name, email or phone number. We also collect information generated as you use our services, for example access logs, as well as information from third parties, like when you access our services through a social media account.
                                    <br /><br />
                                    <strong>Information you give us</strong><br />
                                    When you download Beatroot and create an account, we may collect certain information about you, such as:
                                    <ul>
                                        <li>Name;</li>
                                        <li>Username;</li>
                                        <li>Email address;</li>
                                        <li>Mobile number;</li>
                                        <li>Age</li>
                                        <li>Photographs and;</li>
                                        <li>Certain information when you sign up/in using your social media accounts such as Facebook Google or Apple.If you register using any of your social media accounts mentioned above, you authorize us to access certain account information, such as your public profile (consistent with your privacy settings in your social media accounts), your email address, gender, birthday and profile photo.</li>
                                    </ul>
                                    Once you create an account, you will be able to review and change this information at any time by accessing your “Profile” page! From your profile page you are able to correct or update (other than your email address, mobile number) your name, surname, profile pic at any time by just logging in to Beatroot.

                                    <strong>Information collected when you use our services</strong><br />
                                    In addition to the information, you provide us directly we receive information automatically such as:
                                    <br /><br />
                                    <strong>Activity Data</strong><br />
                                    We collect information about your activity/usage of our services, including: (i) date and time you logged in; (ii) features you’ve been using, clicks and pages which have been shown to you;
                                    (iii) user Accounts and/or User Content you view, like, comment on, share, follow, message, and otherwise interact with, as well as the foregoing that other Users do with respect to your Account and/or User Content;  (iv) time zone, language, screen resolution, and other usage preferences you select when using the app; (v) device settings ;(vi)search terms you type in on the Platform(vii) the search terms you entered into a search engine or app store that may have led you to the Platform; (viii) the mobile platform or service provider you use, and (ix) other device and Platform access information such as your browser type, operating system, IP address, referring/exit pages, and other unique device identifiers, as well as your push notification token.
                                    <br /><br />

                                    <strong>Location Data</strong><br />
                                    If you give us permission, we can collect your precise geolocation (latitude and longitude) through various means, depending on the service and device you’re using, including GPS, Bluetooth or Wi-Fi connections. The collection of your geolocation may occur in the background even when you aren’t using the services if the permission you gave us expressly permits such collection. If you decline permission for us to collect your geolocation, we will not collect it. If you do not wish to share your precise location with us, you can switch off location services via the settings on your mobile device.
                                    Data on the use of the messaging service
                                    When users sent messages using the Beatroot app, these are stored on our servers exclusively, to provide the Service to allow users to have a conversation. Messages are strictly private and only implicate the users involved. Beatroot’s authorized managers do not hold or exercise any rights to view these conversations, except in cases of judicial request, and/or in compliance with legislative or administrative dispositions. Additionally, messages are strictly private and are not accessible to other users not involved in the conversation, nor to any third party.
                                    <br /><br />

                                    <strong>Device and equipment data</strong><br />
                                    We automatically collect information from your device when you are using our app. This information could include your device ID, the operating system used by your device, access times, your mobile device’s geographic location while our application is actively running. Furthermore, we can collect information on your wireless and mobile network connection, like your service provider and signal strength. Also, information on device sensors such as accelerometers, gyroscopes and compasses can be collected.
                                    Information from Other Sources. We may obtain information about you from other sources, including through third party services and organizations to supplement information provided by you. For example, if you access our Services through a third-party application, such as an app store, a third-party login service, or a social networking site, we may collect information about you from that third-party application that you have made public via your privacy settings. Information we collect through these services may include your user identifier and email, stored in that service. This supplemental information allows us to verify information that you have provided to us and to enhance our ability to provide you with information about our business, products, and Services.
                                    Social Networks
                                    When you register with and/or connect using social networking platforms, like Facebook we may collect personal data that you select to allow us access to through their APIs. This data includes photos, gender, date of birth. This saves you from having to remember yet another user name and password and allows you to share some information from your social media account with us. It can be merged with the below ?
                                    Metadata
                                    When you upload User Content in the app such as video, you automatically upload certain metadata that is connected to this content. Metadata is usually technical data that is associated with User Content and will not always be evident to the applicable viewer. In connection with your User Content, the metadata can describe how, when and by whom the piece of User Content was collected and how that User Content is formatted. It further includes certain Personal Information, such as your account name, that enables other Users to trace back the User Content to your Account. Metadata will further consist of additional Personal Information that you chose to provide (e.g., any hashtags used to mark keywords to the User Content). Copy paste from Dubsmash, tik Tok has exactly the same
                                    <br /><br />
                                    <strong>Analytics Information</strong><br />
                                    We have integrated third party analytics tools which help us to measure traffic and usage trends of the app. These tools track the behaviors of the users within the product that help us to improve the app.
                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>How We Use Information</h3>
                            </Col>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>The main reason we use your information is to deliver and improve our services. Additionally, we use your info to help keep you safe, show you suggestions, promote the Platform, and ensure your experience on Beatroot is an enjoyable one.
                                    We may use the information we collect about/from you to:
                                    •	Deliver and improve our products and services, and manage our business;
                                    •	Manage your account and provide you with customer support;
                                    •	Perform research and analysis about your use of, or interest in, our or others’ products, services, or content;
                                    •	Communicate with you by email, telephone and/or mobile devices about products or services that may be of interest to you either from us or other third parties;
                                    •	Develop, display, and track content and advertising tailored to your interests on our Service and other sites, including providing our advertisements to you when you visit other sites; website or mobile application analytics;
                                    •	Verify your eligibility and deliver prizes in connection with contests and sweepstakes; enforce or exercise any rights in our Terms of Use;
                                    •	Perform functions or services as otherwise described to you at the time of collection;
                                    •	Send you requested service and other Platform information ;
                                    •	Send you SMS messages (subject to certain opt-ins described herein);
                                    •	Send you more relevant marketing communications and general Platform information/updates;
                                    •	Create a more personalized User experience and customize certain content you see on the App;
                                    •	Solicit feedback, reviews, and other information regarding your experience with the App, including, without limitation, with respect to certain features and functionalities that you would like to see on the Platform, or that we have recently rolled out onto the Platform;
                                    •	Test new features, service offerings and functionalities on the Platform;
                                    •	resolve disputes between you and other Users;
                                    •	Other purposes that we believe are necessary to protect our rights and the rights of others, or as otherwise described to you at the time of collection
                                    In all circumstances, we may perform these functions directly or use a third party vendor to perform these functions on our behalf who will be obligated to use your personal information only to perform services for us. Also, if you access our Service from a third party social platform, such as Facebook, we may share non-personal information with that platform to the extent permitted by your agreement with it and its privacy settings.

                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>With whom we share your information</h3>
                            </Col>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>With other Users
                                    When you register as a user of the Beatroot App, your app profile will be viewable by other users of the Service. Personal Information that you upload to the App (such as certain Account profile information), certain User Content (such as a video or sound recording clip that you upload to the App), and certain App usage information (such as User Content you “like”), will automatically, or upon your selection, be made publicly available to other Users on the App. You acknowledge and agree that certain Account information (such as your Account profile name, picture, and/or User Content, etc.) that you provide in connection with (i) the registration of your Account and (ii) your general use of the App, such as uploading User Content, will be publicly viewable by all Users of the App. Do not post to Interactive Areas any information, including, without limitation, personal information, that you do not want to be publicly available.

                                    With Service Providers
                                    We may share information, including personal and financial information, with third parties that perform certain services on our behalf. We use third parties to help us operate and improve our services. These third parties assist us with various tasks, including data hosting and maintenance, analytics, customer care, marketing, advertising, payment processing and security operations.
                                    These service providers may have access to personal information needed to perform their functions but are not permitted to share or use such information for any other purposes.
                                    We may also share information with partners who distribute and assist us in advertising our services. For instance, we may share limited information on you in hashed, non-human readable form to advertising partners.

                                    For corporate transactions
                                    We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.
                                    Legal Reasons
                                    We may disclose your information, including personal information:
                                    In response to a subpoena or similar investigative demand, a court order, or a request for cooperation from a law enforcement or other government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases, we may raise or waive any legal objection or right available to us.
                                    When we believe disclosure is appropriate in connection with efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing; to protect and defend the rights, property or safety of our company, our users, our employees, or others; to comply with applicable law or cooperate with law enforcement; or to enforce our Terms of Use or other agreements or policies.

                                    With your consent or at your request
                                    We may use and share non-personal information we collect under any of the above circumstances. We may also share it with third parties to develop and deliver targeted advertising on our Service and on websites or applications of third parties, and to analyze and report on advertising you see. We may combine non-personal information we collect with additional non-personal information collected from other sources. We also may share aggregated, non-personal information, or personal information in hashed, non-human readable form, with third parties, including advisors, advertisers and investors, for the purpose of conducting general business analysis or other business purposes. For example, we may engage a data provider who may collect web log data from you (including IP address and information about your browser or operating system), or place or recognize a unique cookie on your browser to enable you to receive customized ads or content. The cookies may reﬂect de-identified demographic or other data linked to data you voluntarily have submitted to us (such as your email address), that we may share with a data provider solely in hashed, non-human readable form.

                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>How you can access your information</h3>
                            </Col>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>If you have a Beatroot app account, you have the ability to review and update your personal information within the App by opening your account and going to your profile page.
                                    You also may Deactivate or permanently Delete your account at any time by visiting the “Settings” page for your account.
                                    When your Account is deactivated or deleted, we take reasonable efforts to make sure it is no longer viewable on the App. When you delete your account, it is still possible to restore your Account for up to 30 days, if it was accidentally or wrongfully deleted. After 30 days, we begin the process of deleting your Account from our systems.
                                    To prevent abuse and/or misuse of Beatroot app by a User following termination or deletion of a profile/Account we shall retain such information as we deem in our sole discretion may be necessary to ensure that User does not open a new Account and profile in breach of our Terms and Conditions of Use and to ensure compliance with all laws and regulations.

                                    Warning: Even after you remove information from your profile or delete your Account, copies of that information may still be viewable and/or accessed to the extent such information has been previously shared with others or copied or stored by other Users or to the extent such information has been shared with search engines. We cannot control this, nor do we accept any liability for this. If you have given third party applications or websites access to your personal information they may retain such information to the extent permitted under their terms of service or privacy policies.

                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>How we protect your personal information</h3>
                            </Col>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>We take reasonable measures to prevent the unauthorized access or alteration of (and disclosure or destruction of) your personal data, but ultimately, we make no assurances about our ability to prevent any such issues. We follow generally accepted industry standards to protect your information, both during transmission and once we receive it, and to keep such Information confidential (unless it is non-confidential by nature, for example, publicly-available information) and free from any unauthorized alteration. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure, and the nature of security risks is constantly evolving, as are the technical and organizational industry standards relating to management of those risks. While we strive to keep current our security technology and will review, refine and upgrade our security technology as we deem appropriate based on new tools that may become available in the future, the complete and absolute security of any Information collected, stored or used by us cannot therefore be guaranteed. In the unlikely event that an unauthorized third party compromises our security measures, we will not be responsible for any damages directly or indirectly caused by an unauthorized third party’s ability to view, use or disseminate such information. If you ever discover inaccuracies in our data or if your Personal Information changes, we urge you to notify us immediately. We may suspend your use of all or part of the Service without notice if we suspect or detect any breach of security.
                                    If you believe that your account or personal data is no longer secure, please notify us immediately via the contact information set out below.

                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>How Long we Retain your information</h3>
                            </Col>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>We keep your personal information only as long as we need it for legitimate business purposes and as permitted by applicable law. To protect the safety and security of our users on and of our services, we implement a safety retention window of three months following account deletion. During this period, account information will be retained although the account will not be visible on the services anymore.
                                    In practice, we delete or anonymize your information upon deletion of your account (following the safety retention window) or after two years of continuous inactivity, unless:
                                    we must keep it to comply with applicable law (for instance, some “traffic data” is kept for one year to comply with statutory data retention obligations);
                                    there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved; or
                                    the information must be kept for our legitimate business interests, such as fraud prevention and enhancing users’ safety and security. For example, information may need to be kept to prevent a user who was banned for unsafe behavior or security incidents from opening a new account.
                                    Note that even though our systems are designed to carry out data deletion processes according to the above guidelines, we cannot promise that all data will be deleted within a specific timeframe due to technical constraints.

                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Your Rights</h3>
                            </Col>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>You can choose not to provide us with certain information, but that may result in you being unable to use certain features of our Service because such information may be required in order for you to: register as user; purchase products or services; participate in a contest, promotion, survey, or sweepstakes; ask a question; or initiate other transactions.
                                    Our Service may also deliver notifications to your phone or mobile device. You can disable these notifications by going into “App Settings” on the app or by changing the settings on your mobile device.

                                    You may submit a request to access or delete the information we have collected about you by sending your request to us at the email or physical address provided at the bottom of this policy. We will respond to your request consistent with applicable law and subject to proper verification. And we do not discriminate based on the exercise of any privacy rights that you might have.

                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Children’s privacy</h3>
                            </Col>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>Although our Service is a general audience Service, we restrict the use of our service to individuals age 13 and above. We do not knowingly collect, maintain, or use personal information from children under the age of 13. If we ever discover that we have inadvertently collected Personal Information from children under the age of thirteen (13) on or through the Platform, we will delete it from our records as soon as possible.
                                    If you believe that we have personal data about or collected from a child under the relevant age, please contact us at privacy@beatroot.app

                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Changes to this Privacy Policy</h3>
                            </Col>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>We will occasionally update this Privacy Policy to reflect changes in the law, our data collection and use practices, the features of our Service, or advances in technology. When we post changes to this Privacy Policy, we will revise the “last updated” date at the top of this Privacy Policy, which will be posted on the Services under “Settings” and also on www.beatroot.app, and you should regularly check for the most recent version, which is the version that applies. If we make any material changes to this Policy, we will notify you of the changes by reasonable means, which could include notifications through the Services or via email. Please review the changes carefully. Your continued use of the Services following the posting of changes to this policy will mean you consent to and accept those changes. If you do not consent to such changes you can delete your account by following the instructions under Settings.
                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>How to Contact us</h3>
                            </Col>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>If after reviewing this Policy, you still have questions about our information practices, please contact us at hello@beatroot.app with the subject line “Privacy Policy.”
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CardBody>
            </Container>
        );
    }
}

export default withRouter(Privacy);
