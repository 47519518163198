import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, HashRouter, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { setStore } from '@beatroot/web-helper-component-utils-nodejs';
import { configureStore } from '../shared/store';
import createHistory from '../shared/store/history';
import App from '../app/App';
import { IAppState } from "../app/reducers";

const history = createHistory();

function getState() {
    const state: IAppState = {
        config: `${window.location.protocol}//${window.location.host}`
    } as any;

    return state;
}

// Create/use the store and sync with server on initial route
const store =
    window.store ||
    configureStore({
        initialState: getState(),
    });

setStore(store);

render(
    <Provider store={store}>
        <Router history={history}>
            <HelmetProvider>
                <HashRouter>
                    <App />
                </HashRouter>
            </HelmetProvider>
        </Router>
    </Provider>,
    document.getElementById('app')
);

if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept();
    }

    if (!window.store) {
        window.store = store;
    }
}
