import React from 'react';

const IconCreate = () => {
    return (
        <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="lp-icon_create" fill-rule="nonzero">
                <g id="Group" opacity="0.8" fill="#FFFFFF" className="icon-bg">
                    <path
                        d="M0,25 C0,19.9 0.4,10.4 5.4,5.4 C10.4,0.4 19.9,0 25,0 C30.1,0 39.6,0.4 44.6,5.4 C49.6,10.4 50,19.9 50,25 C50,30.1 49.6,39.6 44.6,44.6 C39.6,49.6 30.1,50 25,50 C19.9,50 10.4,49.6 5.4,44.6 C0.4,39.6 0,30.1 0,25 Z"
                        id="profile_bg-2"></path>
                </g>
                <path
                    d="M38.6,22.35 C38.6,21.7977153 38.1522847,21.35 37.6,21.35 L28.9,21.35 C28.7126892,21.3555951 28.531372,21.2836436 28.3988642,21.1511358 C28.2663564,21.018628 28.1944049,20.8373108 28.2,20.65 L28.2,11.65 C28.2074896,11.3545259 28.0999272,11.0676928 27.9,10.85 C27.7217438,10.6524755 27.4659703,10.5428582 27.2,10.55 L22.7,10.55 C22.4340297,10.5428582 22.1782562,10.6524755 22,10.85 C21.8000728,11.0676928 21.6925104,11.3545259 21.7,11.65 L21.7,20.65 C21.7055951,20.8373108 21.6336436,21.018628 21.5011358,21.1511358 C21.368628,21.2836436 21.1873108,21.3555951 21,21.35 L12.3,21.35 C12.0303864,21.3215524 11.7653404,21.4351435 11.6,21.65 C11.4024755,21.8282562 11.2928582,22.0840297 11.3,22.35 L11.3,22.75 L38.5,26.45 L38.6,22.35 Z M11.4,27.75 C11.3928582,28.0159703 11.5024755,28.2717438 11.7,28.45 C11.8891647,28.6316809 12.1379796,28.7383159 12.4,28.75 L21.1,28.75 C21.2873108,28.7444049 21.468628,28.8163564 21.6011358,28.9488642 C21.7336436,29.081372 21.8055951,29.2626892 21.8,29.45 L21.8,38.45 C21.8,39.0022847 22.2477153,39.45 22.8,39.45 L27.3,39.45 C27.8522847,39.45 28.3,39.0022847 28.3,38.45 L28.3,29.45 C28.2944049,29.2626892 28.3663564,29.081372 28.4988642,28.9488642 C28.631372,28.8163564 28.8126892,28.7444049 29,28.75 L37.7,28.75 C38.2522847,28.75 38.7,28.3022847 38.7,27.75 L38.7,27.25 L11.5,23.55 L11.4,27.75 Z"
                    id="Path_2636" fill="#161823" className="icon-shape"></path>
            </g>
        </svg>
    );
}
export default IconCreate;