import * as React from 'react';
import { FooterArea } from "./sections/Footer";
import { eAsidePosition, VideoArea } from "./sections/VideoSection";
import { WelcomeArea } from "./sections/Welcome";
import { VideoAreaMobile } from './sections/VideoSectionMobile';
import MediaQuery from 'react-responsive'
// import {DiscoverArea} from "./sections/Discover";

export const layout = require(`../web-styles/layout/structure.scss`);
export const styles = require(`./sections/styles/sections.scss`);
const lp = require(`./styles/lp.scss`);
export const btn = require(`../web-styles/components/btns.scss`);

interface ILandingPageProps {
}

interface ILandingPageState {
    asidePosition: eAsidePosition;
}

class LandingPage extends React.Component<ILandingPageProps, ILandingPageState> {

    constructor(props: ILandingPageProps) {
        super(props);

        this.state = {
            asidePosition: eAsidePosition.TOP
        };
    }

    render() {
        return (
            <div className={`${lp.main} justify-content-center align-items-center`}>
                <WelcomeArea
                    enterViewport={() => {
                        this.setState({
                            asidePosition: eAsidePosition.TOP,
                        });
                    }}
                    exitViewport={() => {
                        this.setState({
                            asidePosition: eAsidePosition.MIDDLE,
                        });
                    }}
                />
                {/*<DiscoverArea />*/}

                <MediaQuery minDeviceWidth={1224}>
                    <VideoArea asidePosition={this.state.asidePosition} />
                </MediaQuery>
                <MediaQuery maxDeviceWidth={1223}>
                    <VideoAreaMobile asidePosition={this.state.asidePosition} />
                </MediaQuery>

                <FooterArea
                    enterViewport={() => {
                        this.setState({
                            asidePosition: eAsidePosition.BOTTOM,
                        });
                    }}
                    exitViewport={() => {
                        this.setState({
                            asidePosition: eAsidePosition.MIDDLE,
                        });
                    }}
                />
            </div>
        );
    }
}

export default LandingPage;