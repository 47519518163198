import React from 'react';

const IconDiscover = () => {
    return (
        <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="lp-icon_discover" fill-rule="nonzero">
                <g id="Group" opacity="0.8" fill="#FFFFFF" className="icon-bg">
                    <path d="M0,25 C0,19.9 0.4,10.4 5.4,5.4 C10.4,0.4 19.9,0 25,0 C30.1,0 39.6,0.4 44.6,5.4 C49.6,10.4 50,19.9 50,25 C50,30.1 49.6,39.6 44.6,44.6 C39.6,49.6 30.1,50 25,50 C19.9,50 10.4,49.6 5.4,44.6 C0.4,39.6 0,30.1 0,25 Z" id="profile_bg-2"></path>
                </g>
                <path d="M23,34.9999997 C24.6936991,34.9923227 26.3655392,34.6170116 27.9,33.9 L31.9,37.9 C32.7018137,38.6812145 33.7806076,39.1127321 34.9,39.1 C35.9780846,39.1269606 37.0213809,38.7174655 37.7932956,37.964378 C38.5652103,37.2112905 39.0003371,36.1784216 39,35.1 L39,34.9999997 C39.0300464,33.9067718 38.5937352,32.8523531 37.8,32.1 L33.9,28.2 C34.6962754,26.582621 35.1070141,24.8027535 35.1,22.9999997 C35.1000001,18.7128129 32.812813,14.7512885 29.1000001,12.6076949 C25.3871871,10.4641013 20.8128129,10.4641013 17.0999999,12.6076949 C13.387187,14.7512885 11.0999999,18.7128129 11.1,22.9999997 C11.0514949,26.17985 12.2863992,29.2450944 14.5257257,31.5032387 C16.7650521,33.761383 19.8198554,35.0218943 23,34.9999997 Z M23,29.3000001 C19.5084538,29.2897326 16.6652477,26.4909516 16.6,23 L16.6,22.9 C16.6102674,19.4084538 19.4090484,16.5652477 22.9,16.5 L22.9,16.5 C26.4048123,16.4778014 29.2670054,19.2952727 29.3,22.8 L29.3,22.9 C29.326799,24.5881225 28.6749005,26.2163626 27.4905042,27.4195588 C26.3061079,28.6227551 24.6883352,29.3002127 23,29.3000001 L23,29.3000001 Z" id="Path_2928" fill="#161823" className="icon-shape"></path>
            </g>
        </svg>
    );
}
export default IconDiscover;