import { getPageId } from "@beatroot/web-framework-utils-nodejs/router";

export const pages = {
    COMING_SOON: getPageId('COMING_SOON'),
    LANDING_PAGE: getPageId('LANDING_PAGE'),
    TERMS: getPageId('TERMS'),
    HELP: getPageId('HELP'),
    GUIDELINES: getPageId('GUIDELINES'),
    PRIVACY: getPageId('PRIVACY'),
    CONTACT: getPageId('CONTACT'),

};

//Map pages to actual page urls
export const pageRoutes = {
    LANDING_PAGE: '/',
    TERMS: '/terms',
    GUIDELINES: '/guidelines',
    HELP: '/help',
    CONTACT: '/contact',
    PRIVACY: '/privacy',
};
