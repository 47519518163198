import * as React from "react";
import { Col, Container, Row } from "reactstrap";
import { Navigation } from "./video-section/Navigation";
import { WrapperContent } from "./video-section/WrapperContent";
import { Phone } from "./video-section/Phone";
// import ReactPlayer from "react-player";

export const styles = require(`./styles/sections.scss`);

export enum eAsidePosition {
    TOP,
    MIDDLE,
    BOTTOM
}

interface IVideoAreaProps {
    asidePosition: eAsidePosition;
}

interface IVideoAreaState {
    activeContentSection: string;
}

export class VideoArea extends React.PureComponent<IVideoAreaProps, IVideoAreaState> {
    constructor(props: IVideoAreaProps) {
        super(props);

        this.state = {
            activeContentSection: "dance",
        };
    }

    render() {
        return (
            <section className={`${styles["video-area"]} ${styles.section} video-area`}>
                <Container>
                    <Row className={`justify-content-between`}>
                        <Col xl={{ size: 6, order: 1 }} lg={{ size: 12 }} sm={{ size: 12 }} xs={{ size: 12 }}>
                            <div
                                className={`
                                    ${styles.aside} 
                                    ${styles.positionFixed} 
                                    ${this.props.asidePosition == eAsidePosition.TOP ? styles.asideTop : styles.asideBottom} 
                                    ${this.props.asidePosition != (eAsidePosition.MIDDLE || eAsidePosition.BOTTOM) && styles.positionAbsolute}
                                `}
                            >
                                <div className={styles['phone-section']} >
                                    <div className={`${styles.wrapper} ${styles.phone}`}>
                                        <Phone wallpaper={this.state.activeContentSection} />
                                    </div>
                                </div>

                                <Navigation activeId={this.state.activeContentSection} />
                                <div className={styles['bg-mobile']} />
                            </div>
                        </Col>

                        <Col xl={{ size: 6, order: 2 }} lg={{ size: 12 }} className={`${styles['mobile-video']} d-xl-block`}>
                            <WrapperContent
                                idContent={"danceContent"}
                                id={"dance"}
                                subtitle={"How it works"}
                                title={"Create. Compete. Collaborate."}
                                txt={"Dance to your favorite beat alone, with a friend, or with the whole world. See how your moves stack up to others, discover awesome dance videos and join a global dance lover community"}
                                // buttonTitle={"How To Start"}
                                buttonClass={'primary'}
                                fullyEnterContentViewport={() => {
                                    this.setState({
                                        activeContentSection: "dance",
                                    });
                                }}
                            />
                            <WrapperContent
                                id={"discover"}
                                idContent={"discoverContent"}
                                subtitle={"Discover"}
                                title={"Freshly baked videos. Every second"}
                                txt={"Tap into an endless stream of original, fresh & creative dance videos. Search, explore and see more of what interests you based on people you follow and videos you like. Inspire and get inspired by others!"}
                                // buttonTitle={"Learn More"}
                                buttonClass={'secondary'}
                                fullyEnterContentViewport={() => {
                                    this.setState({
                                        activeContentSection: "discover",
                                    });
                                }}
                            />
                            <WrapperContent
                                id={"solo"}
                                idContent={"soloContent"}
                                subtitle={"Solo Video"}
                                title={"Showoff your best dance moves"}
                                txt={"Express yourself by dancing to your favorite beat and showoff your talent for the whole world to admire."}
                                // buttonTitle={"Learn More"}
                                buttonClass={'secondary'}
                                fullyEnterContentViewport={() => {
                                    this.setState({
                                        activeContentSection: "solo",
                                    });
                                }}
                            />
                            <WrapperContent
                                id={"duo"}
                                idContent={"duoContent"}
                                subtitle={"Duo Challenge"}
                                title={"Why dance alone?"}
                                txt={"Got a sick move? Turn your video into a casual challenge and let the community reply with their own version. It’s all about fun, casual and entertaining videos!"}
                                // buttonTitle={"Learn More"}
                                buttonClass={'secondary'}
                                fullyEnterContentViewport={() => {
                                    this.setState({
                                        activeContentSection: "duo",
                                    });
                                }}
                            />
                            <WrapperContent
                                id={"relay"}
                                idContent={"videoContent"}
                                subtitle={"Video Relay"}
                                title={"Dance it forward!"}
                                txt={"Record the first 15s of your video and give control to your friends or followers to finish the story! Throw a fun video to them and see what comes out at the other end!"}
                                // buttonTitle={"Learn More"}
                                buttonClass={'primary'}
                                fullyEnterContentViewport={() => {
                                    this.setState({
                                        activeContentSection: "relay",
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}