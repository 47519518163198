import * as React from "react";
import {CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/pro-regular-svg-icons";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {pageRoutes} from "../../routes";
import queryString from "query-string";

const styles = require(`./styles/pages.scss`);
const btn = require(`../../web-styles/components/btns.scss`);


class GuidelinesPage extends React.Component<RouteComponentProps, {}> {
    render() {
        const parsed = queryString.parse(this.props.location.search);
        const firstTemplate = (parsed.template instanceof Array ? parsed.template[0] : parsed.template) || 'default';

        return (
            <Container fluid={true} className={styles[firstTemplate]}>
                <CardHeader className={`${styles.header} ${parsed.hideHeader === "true" ? styles['hide-header'] : ''}`}>
                    <Link to={pageRoutes.LANDING_PAGE} className={`${styles.txt} ${btn.link}`}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                        Back to main page
                    </Link>
                    <div className={styles.logo} />
                </CardHeader>

                <CardBody className={`${styles.body} ptb_100`}>
                    <Container className={`container`}>
                        <Row className={`row justify-content-center`}>
                            <div className={`col-12 col-md-10 col-lg-7`}>
                                <div className={`section-heading text-center`}>
                                    <h2 className={` ${styles.h2} text-capitalize`}>Community Guidelines</h2>
                                </div>
                            </div>
                        </Row>
                        <Row className={`row justify-content-center`}>
                            <Col className={`col-12`}>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Welcome to the Beatroot community. If you’re honest, kind and respectful to others, you’ll always be welcome here. If you choose not to be, you may not last. Our goal is to allow users to express themselves freely as long as it doesn’t offend others.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    Our Community Guidelines apply to everyone and to everything on Beatroot. We will remove any content – including video, audio, livestream, images, comments, and text – that violates our Community Guidelines. Individuals are notified of our decisions and can appeal if they believe no violation has occurred. We will suspend or ban accounts and/or devices that are involved in severe or repeated violations; we will consider information available on other platforms and offline in these decisions. When warranted, we will report the accounts to relevant legal authorities. We also encourage our community members to use the tools we provide on Beatroot to report any content they believe violates our Community Guidelines.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    In the interest of keeping our community a safe and supportive one, this document lays out a framework for what does and doesn't fly on Beatroot.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    We look forward to the chance to grow this community together. The first and most important thing we ask of everyone is to:
                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Be authentic</h3>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    You are the owner of all of the content that you create and post on Beatroot yourself. However, do not post any content that you are not entitled to share or post. Only upload videos and other content that you have created or that you have been authorised to use. Do not use any content in your posts or other uploads that you do not own the copyright to, such as music records or portions of videos created by anyone else. Do not post any content that you have copied or accessed from any other source including the internet, for which you have not obtained authorisation to post. You are requested to respect the copyrights, trademarks and other intellectual property rights of other persons. Your video(s) will be taken down without notice to you if we receive a complaint that you have infringed someone else’s intellectual property rights.
                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Don’t threaten, cyberbully, harass other users</h3>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Never threaten or post any video or other content threatening any person or a group of persons. Threats of causing harm to any person or property are strictly prohibited. This includes threats of physical harm, theft, property damage, reputational damage, defamatory content and threats of financial loss.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    Do not post any content that promotes, incites or condones violence, hatred or discrimination against any person or groups of persons based on nationality, race, ethnicity, region, religion, caste, disability, deformity, age, sex, gender, sexual orientation, gender identity or political affiliation.
                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Don't be a bot (or act like one).</h3>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Don't engage in repeated, unwanted, or unsolicited interactions that negatively impact the members of this community, whether automated or manual. No spammy links or dubious offers, either.
                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Keep content appropriate for a diverse audience.</h3>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    <strong>Self Harm, suicide and dangerous content</strong>
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    The welfare and well-being of all users of Beatroot is our utmost priority and in furtherance of this we are looking to create a safe environment for our users. Therefore we do not allow any content to be posted that depicts, glorifies, encourages, instructs, or promotes any form of self-harm or self-injury.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    However, as an exception any content that provides support, resources, coping mechanisms for those struggling with thoughts of self-harm or suicide are permitted.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    Do not post any content that encourages, promotes glorifies or incentivises persons to engage in any dangerous or risky activity that has an imminent risk of physical or mental injury.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    <strong>Violent and graphic content</strong>
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    Do not post violent, gruesome or graphic content that is chiefly intended to be shocking, sensational, or gratuitous and depicting violent or accidental deaths involving real people.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Such content (subject to some restrictions) may be posted for the limited purpose of raising awareness and/or promoting discussion about certain newsworthy events, as long as sufficient information is provided to warn persons of the violent or graphic nature of the content.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    <strong>Sexually explicit content and solicitation</strong>
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Nudity or any content that displays any private parts of a person is not permitted on Beatroot and further we prohibit all pornographic and sexual content including digitally created content. Pornographic and sexual content includes content that depicts or is related to sexually explicit language, sexual fetishes, sexual arousal and sexual activity including penetrative sex, non-penetrative sex and oral sex.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    All content that facilitates, promotes or coordinates sexual encounters between persons is prohibited and sexually explicit language is prohibited.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    All content that promotes, glorifies or depicts any form of sexual violence or sexual exploitation or non-consensual sexual acts are prohibited.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    <strong>Safety of minors</strong>
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    We are committed to ensuring that Beatroot is a safe and accessible platform and in pursuance of this, we are deeply committed to ensuring the safety of minors. All users must have attained at least 13 years of age.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    All and any content, including content that is in a digital format, depicting nude or even partially nude minors or the private parts of minors are strictly prohibited.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Any content, including content that is in a digital format, that depicts a minor in a sexual context or in connection with any sexual activity including penetrative sex, non-penetrative sex and oral sex, is strictly prohibited. All content that sexually objectifies minors is prohibited.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    Any content, including content that is in a digital format, that portrays, promotes or glorifies the sexual exploitation of minors or paedophilia or violence against minors is prohibited.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Any and all activity related to grooming of minors for the purposes of engaging in sexual abuse, sexual exploitation, or sexual trafficking of such minors is strictly prohibited. Users are prohibited from posting any content that encourages, incentivizes or blackmails a minor to transmit any content that is sexual in nature. All posts that involve or engage a minor in a sexually explicit conversation are prohibited.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    All instances or suspected instances of sexual exploitation of minors shall be reported to the authorities.
                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Follow national and local laws.</h3>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Hopefully this goes without saying, but we figured we'd say it anyway: If it's illegal, it's not allowed on Beatroot.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    <strong>Unlawful activities, terrorists and organised crime syndicates</strong>
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Do not post any content that is unlawful or which is prohibited under any national or local laws which apply to you. Do not use Beatroot for any illegal activities or for the conduct of lawful activities in an unlawful manner.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    All content that promotes, glorifies or incites the commission of any unlawful activities or offences is prohibited.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Terrorists are prohibited from using Beatroot. Terrorists include non-state actors that use planned and deliberate violence or threats of violence against civilians, in the pursuit of any political, religious, racial, nationalist or ideological objects.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    All content that promotes, supports or glorifies any terrorists or criminals or criminal and unlawful activities is prohibited.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    <strong>Drugs and Narcotics</strong>
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    In order to ensure safety of users, you shall not post any content that depicts, promotes, encourages or glorifies the consumption, possession, manufacture or trade of drugs, narcotics and other controlled substances.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    You shall not sell, offer for sale, trade or solicit any drugs, narcotics or other controlled substances on Beatroot.
                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Do not impersonate</h3>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Do not impersonate another person or organisation and do not post any content on Beatroot by using someone else’s name, details, images, logos, trademarks, trade names or brand names. Do not create any accounts for the purpose of misleading others.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    However, parody accounts, fan accounts and other accounts that do not mislead other users of Beatroot are permitted.
                                </div>
                            </Col>
                            <Col className={`col-12`}>
                                <h3 className={`${styles.title} mb-0`}>Do not threaten Beatroot security</h3>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    All activity and content that undermines the security of Beatroot is prohibited.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    You are prohibited from circulating or distributing any files on Beatroot that contain viruses, trojans, malware, ransomware or other materials that are malicious or harmful.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    Do not attempt to hack the Beatroot website, applications, software, servers or associated networks or circumvent its measures to restrict user access.
                                </div>
                                <div className={`${styles.txt} px-0`}>
                                    Do not attempt to make unauthorised copies, modify, adapt, translate, reverse engineer, disassemble, decompile or create any derivative works of the Services or any content included therein, including any files, tables or documentation (or any portion thereof) or determine or attempt to determine any source code, algorithms, methods or techniques embodied by the Services.
                                </div>
                                <div className={`${styles.txt} px-0 py-3`}>
                                    If you violate these Guidelines, we may in our sole discretion take any suitable action against you, which may include the removal of the offending content from Beatroot, termination of your Beatroot account, deactivation of your Beatroot account, and/or notification of the concerned authorities.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CardBody>
            </Container>
        );
    }
}

export default withRouter(GuidelinesPage);
