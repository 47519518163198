import * as React from "react";
import {Button} from "reactstrap";
import {Watch} from "scrollmonitor-react";
import {btn} from "../../LandingPage";
import {styles} from "../VideoSection";

interface IWrapperContentState {
    isBelowViewport: boolean;
}

interface IWrapperContentProps {
    id: string;
    idContent: string;
    subtitle: string;
    title: string;
    txt: string
    buttonTitle: string;
    buttonClass: string;
    enterViewport?: (watcher: any, childProps: IContentProps) => void;
    fullyEnterViewport?: (watcher: any, childProps: IContentProps) => void;
    fullyEnterContentViewport?: (watcher: any, childProps: IContentProps, wasBelowViewport: boolean) => void;
    exitViewport?: (watcher: any, childProps: IContentProps) => void;
    partiallyExitViewport?: (watcher: any, childProps: IContentProps) => void;
}

export const WrapperContent =  Watch(class extends React.Component<IWrapperContentProps, IWrapperContentState> {
    constructor(props: IWrapperContentProps) {
        super(props);

        this.state = {
            isBelowViewport: false,
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IWrapperContentProps>): boolean {
        return this.props.subtitle != nextProps.subtitle ||
            this.props.id != nextProps.id ||
            this.props.idContent != nextProps.idContent ||
            this.props.title != nextProps.title ||
            this.props.txt != nextProps.txt ||
            this.props.buttonTitle != nextProps.buttonTitle;
    }

    render() {
        return (
            <div className={`${styles.wrapper} ${styles.center}`} id={this.props.id}>
                <Content
                    id={this.props.idContent}
                    subtitle={this.props.subtitle}
                    title={this.props.title}
                    txt={this.props.txt}
                    buttonTitle={this.props.buttonTitle}
                    enterViewport={(watcher) => {
                        this.setState({
                            isBelowViewport: watcher.isBelowViewport,
                        });
                    }}
                    exitViewport={(watcher) => {
                        this.setState({
                            isBelowViewport: watcher.isBelowViewport,
                        });
                    }}
                    fullyEnterViewport={(watcher, childProps) => { this.props.fullyEnterContentViewport && this.props.fullyEnterContentViewport(watcher, childProps, this.state.isBelowViewport); }}
                    buttonClass={this.props.buttonClass}
                />
            </div>
        );
    }
});

interface IContentProps {
    id: string;
    subtitle: string;
    title: string;
    txt: string
    buttonTitle: string;
    buttonClass: string;
}

const Content = Watch(class extends React.Component<IContentProps, {}> {
    shouldComponentUpdate(nextProps: Readonly<IContentProps>): boolean {
        return this.props.id != nextProps.id ||
            this.props.subtitle != nextProps.subtitle ||
            this.props.title != nextProps.title ||
            this.props.txt != nextProps.txt ||
            this.props.buttonTitle != nextProps.buttonTitle;
    }

    render() {
        return (
            <div id={this.props.id} className={styles.content}>
                {this.props.subtitle && <div className={styles.subtitle}>{this.props.subtitle}</div>}
                {this.props.title && <div className={styles.title}>{this.props.title}</div>}
                {this.props.txt && <div className={styles.txt}>{this.props.txt}</div>}
                {this.props.buttonTitle && <Button title={this.props.buttonTitle} role={"button"} className={btn[this.props.buttonClass]}>{this.props.buttonTitle}</Button>}
            </div>
        );
    }
});
