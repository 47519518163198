import * as React from "react";
import { Link } from 'react-router-dom';
import { Watch } from "scrollmonitor-react";
import { Col, Container, NavLink, Row } from "reactstrap";
// import {Input} from "@beatroot/web-helper-component-utils-nodejs/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { pageRoutes } from "../../routes";

import { btn } from "../LandingPage";
import { DownloadButtons } from "./DownloadButtons";
import MediaQuery from 'react-responsive'
// import {faArrowToTop} from "@fortawesome/pro-regular-svg-icons";
const styles = require(`./styles/sections.scss`);
// const input = require('../../web-styles/components/inputs.scss');

interface IFooterAreaProps {
    enterViewport: (watcher: any, childProps: any) => void;
    exitViewport: (watcher: any, childProps: any) => void;
}

export const FooterArea = Watch(class extends React.Component<IFooterAreaProps, {}> {
    render() {
        return <div className={styles.footerSection}>
            <MediaQuery minDeviceWidth={1224}>
                <div className={styles.preFooter} />
            </MediaQuery>
            <section className={`${styles["footer"]} ${styles.section}`}>
                <div className={`${styles.symbol} ${styles["symbol-absolute"]}`} />
                <div className={styles.txt}>Download Now<br />
                    Free on iOS & Android
                </div>
                <DownloadButtons />
                {/*<Container>*/}
                {/*    <Row className={`justify-content-between align-items-center`}>*/}

                {/*        <Col lg={8} className={"offset-lg-2"} xs={12}>*/}
                {/*            <div className={styles.form}>*/}
                {/*                <Input*/}
                {/*                    id={"email"}*/}
                {/*                    type={"text"}*/}
                {/*                    disabled={true}*/}
                {/*                    submitButtonText={"Text me the app"}*/}
                {/*                    textLabel={""}*/}
                {/*                    isDisabled={true}*/}
                {/*                    classNames={{*/}
                {/*                        inputContainer: input.container,*/}
                {/*                        inputGroup: input.group,*/}
                {/*                        inputForm: input.form,*/}
                {/*                        inputField: input.field,*/}
                {/*                        inputLabel: input.label,*/}
                {/*                        inputCustom: input.search,*/}
                {/*                        inputIcon: input.icon,*/}
                {/*                        inputDisabled: input.disabled,*/}
                {/*                        inputSubmitButton: input.btn,*/}
                {/*                        inputBtnDisabled: input['btn-disabled']*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </Col>*/}

                {/*    </Row>*/}
                {/*</Container>*/}
                <div className={styles.bottom}>
                    {/* <div className={styles.separator}/> */}

                    <Container>
                        <Row className={`justify-content-between align-items-center`}>
                            <Col lg={{ size: 4, offset: 4 }} md={12} className={styles.links}>
                                <Link title={"Terms & Conditions"} to={pageRoutes.TERMS} className={btn.link}>
                                    Terms
                                </Link>
                                <Link title={"Privacy"} to={pageRoutes.PRIVACY} className={btn.link}>
                                    Privacy
                                </Link>
                                <Link title={"Guidelines"} to={pageRoutes.GUIDELINES} className={btn.link}>
                                    Guidelines
                                </Link>
                                {/*<Link title={"Contact"} to={pageRoutes.TERMS} className={btn.link}>*/}
                                {/*    Contact*/}
                                {/*</Link>*/}
                                <a title={"Help"} href={"https://support.beatroot.app/hc/en-us"} className={btn.link} target={"_blank"}>
                                    Help
                                </a>
                                <a title={"Community"} href={"https://support.beatroot.app/hc/en-us/community/topics"} className={btn.link} target={"_blank"}>
                                    Community
                                </a>
                            </Col>
                            {/*<div className={styles.goTop}>*/}
                            {/*    <FontAwesomeIcon icon={faArrowToTop} />*/}
                            {/*</div>*/}
                        </Row>
                        <Row className={`justify-content-between align-items-center my-5`}>
                            <Col lg={12} sm={12}>
                                <div className={styles.copyright}>
                                    <div className={styles.symbol} />
                                    <div className={styles["small-txt"]}>&copy; 2021 Beatroot</div>
                                </div>
                                {/*</Col>*/}
                                {/*<Col lg={6} sm={12}>*/}
                                <div className={styles.media}>
                                    <NavLink title={"Facebook"} target="_blank" rel="noopener noreferrer"
                                        href={"https://www.facebook.com/beatrootapp/"}
                                        className={`${btn.icon} ${btn.iconSmall} ${styles.icon}`}>
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </NavLink>
                                    {/*<NavLink title={"Twitter"} href={"http://www.twitter.com"}*/}
                                    {/*         className={`${btn.icon} ${btn.iconSmall} ${styles.icon}`}>*/}
                                    {/*    <FontAwesomeIcon icon={faTwitter}/>*/}
                                    {/*</NavLink>*/}
                                    <NavLink target="_blank" rel="noopener noreferrer" title={"Instagram"}
                                        href={"https://www.instagram.com/beatroot.app/"}
                                        className={`${btn.icon} ${btn.iconSmall} ${styles.icon}`}>
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </NavLink>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </div>;
    }
});