import React from 'react';

const IconVideo = () => {
    return (
        <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="lp-icon_video-relay" fill-rule="nonzero">
                <g id="Group" opacity="0.8" fill="#FFFFFF" className="icon-bg">
                    <path
                        d="M0,25 C0,19.9 0.4,10.4 5.4,5.4 C10.4,0.4 19.9,0 25,0 C30.1,0 39.6,0.4 44.6,5.4 C49.6,10.4 50,19.9 50,25 C50,30.1 49.6,39.6 44.6,44.6 C39.6,49.6 30.1,50 25,50 C19.9,50 10.4,49.6 5.4,44.6 C0.4,39.6 0,30.1 0,25 Z"
                        id="profile_bg-2"></path>
                </g>
                <g id="Group_2318" transform="translate(9.000000, 13.000000)" fill="#161823" className="icon-shape">
                    <path
                        d="M12.9,23.35 C11.7328249,23.3550752 10.7076194,22.575919 10.4,21.45 L21.4,15.05 C21.9336838,14.7355541 22.4076312,14.3293135 22.8,13.85 C23.7011012,12.7812579 23.7011012,11.2187421 22.8,10.15 C22.403361,9.6885772 21.9296691,9.2994731 21.4,9 L10.4,2.6 C10.7076194,1.47408097 11.7328249,0.69492476 12.9,0.699975351 C13.6112153,0.698620508 14.3069925,0.907353678 14.9,1.3 L29.1,9.3 C30.4,10.1 31,10.9 31,11.9 C30.9248936,13.0619474 30.184229,14.0754884 29.1,14.5 L14.9,22.5 C14.3995692,23.0758869 13.6619222,23.3893869 12.9,23.35 Z"
                        id="Subtraction_38"></path>
                    <path
                        d="M3.1,21.55 C3.7011234,21.5379665 4.28808192,21.3653316 4.8,21.05 L16.3,14.35 C17.1977529,13.9672389 17.8124646,13.1220102 17.9,12.15 C17.7950675,11.196585 17.1831287,10.3742923 16.3,10 L4.8,3.3 C4.28808192,2.98466839 3.7011234,2.81203353 3.1,2.8 C2.52885561,2.81045013 1.98559513,3.04881418 1.59116777,3.46202378 C1.19674042,3.87523339 0.983888332,4.42898728 1,5 L1,19.3 C0.956050317,19.8711719 1.1429382,20.4361191 1.51883696,20.8684026 C1.89473572,21.3006862 2.42825866,21.5642115 3,21.6 L3.1,21.55 Z"
                        id="Path_2493"></path>
                </g>
            </g>
        </svg>
    );
}
export default IconVideo;