import React from 'react';
const styles = require('../../web-styles/layout/notfound');

export default error => {
    console.error(error.error || error);

    return (
        <div className={styles.notFound}>
            ERROR: {error.error instanceof Error ? error.error.message : error.message}
        </div>
    );
};
